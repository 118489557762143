'use client';
import React from 'react';
import DynamicComponentLoader from 'app/DynamicComponentLoader';
import { HeroContentStoryblok } from 'generated/storyblok/component.types';

import RichTextBlock from 'components/common/RichTextBlock';

import style from './HeroContent.module.scss';

const HeroContent = ({ blok }: HeroContentStoryblok ) => {
    return (
        <div className={style.container}>
            <div className={style.wrapper}>
                <div>
                    <h1>{blok.title}</h1>
                    <RichTextBlock blok={blok.body} />
                    <div className={style.buttonwrapper}>
                        {blok.children?.map((blokChild) => (
                            <DynamicComponentLoader
                                blok={blokChild}
                                type={blokChild.component}
                                key={blokChild._uid}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeroContent;
