import { getComponent } from 'app/GetComponent';

const DynamicComponentLoader = ({ type, blok }: { type: string, blok: any }) => {
    const Component = getComponent(type);

    if (!Component) {
        return null;
    }

    return <Component blok={blok} />;
};

export default DynamicComponentLoader;
