'use client';
import React from 'react';

import style from './ContactUsDialog.module.scss';

type Props = {
    handleClose: () => void;
}

const Confirmation = ({ handleClose }: Props) => {
    return (
        <div className={style.confirmationContainer}>
            <div className={style.confirmationContent}>
                <p className={style.confirmationHeader}>Tack!</p>
                <p>Vi ser fram emot att få lyssna in på era frågor och eventuella utmaningar.</p>
                <button className={style.button} onClick={handleClose}>
                    Stäng
                </button>
            </div>
        </div>
    )
}

export default Confirmation;
