import { Dispatch, FormEvent, ReactNode, SetStateAction, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ContactFormStoryblok } from 'generated/storyblok/component.types';
import { formatQuizContactForm, QuizContactForm } from 'helpers/slack.helper';
import { slackClient } from 'utils/slack-client';

import RichTextBlock from 'components/common/RichTextBlock';

import style from './ContactForm.module.scss';

export const ContactForm = ({
    blok,
    setHasSubmitted,
    children,
}: {
    blok: ContactFormStoryblok,
    setHasSubmitted: Dispatch<SetStateAction<boolean>>,
    children: ReactNode,
}) => {
    const [isFadingOut, setIsFadingOut] = useState(false);
    const [contactMethod, setContactMethod] = useState<string>('phone');

    const postToSlack = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = Object.fromEntries(new FormData(e.target as HTMLFormElement)) as QuizContactForm;

        try {
            setIsFadingOut(true);
            await slackClient(formatQuizContactForm(formData));
            setTimeout(() => {
                setHasSubmitted(true);
            }, 1000);

            console.log('Form submitted');
        } catch (error) {
            console.error('Form submission failed', error);
            alert('There was an error submitting the form. Please try again.');
        }
    };

    return (
        <div className={`${style['contact-form']} ${isFadingOut ? style['fade-out'] : ''}`}>
            <div className={style['column-left']}>
                <h2 className={style['title']}>{blok.title}</h2>
                <RichTextBlock blok={blok.body} className={style['body']} />
            </div>
            <div className={style['column-right']}>
                <form onSubmit={postToSlack} className={style['form-container']}>
                    <label htmlFor="name">Email</label>
                    <input type="email" name="email" placeholder="E-post" className={style['input']} required />
                    <label htmlFor="phone">Telefonnummer</label>
                    <input type="text" name="phone" placeholder="Telefonnummer" className={style['input']} required />
                    <RadioGroup name="contact-method">
                        <FormControlLabel
                            value="phone"
                            control={<Radio  />}
                            label="Kontakta mig via telefon"
                            checked={contactMethod === 'phone'}
                            onChange={() => setContactMethod('phone')}
                        />
                        <FormControlLabel
                            value="email"
                            control={<Radio />}
                            label="Kontakta mig via e-post"
                            checked={contactMethod === 'email'}
                            onChange={() => setContactMethod('email')}
                        />
                    </RadioGroup>
                    <label htmlFor="message">Meddelande</label>
                    <textarea placeholder="Meddelande (frivillig)" name="message" className={style['textarea']} />
                    {children}
                </form>
            </div>
        </div>
    );
};
