type FormatQuizContactFormFn = (data: QuizContactForm) => string;

export type QuizContactForm = {
    email: string;
    phone: string;
    'contact-method': string;
    message?: string;
};

export const formatQuizContactForm: FormatQuizContactFormFn = (data: QuizContactForm) => {
    return JSON.stringify({ text: `The form has been submitted. \`\`\`${JSON.stringify(data)}\`\`\`` });
};
