import { GartnerCarouselStoryblok } from 'generated/storyblok/component.types';

import Gartner from './Gartner';

// import style from './Gartner.module.scss';

const GartnerCarousel = ({ blok }: { blok: GartnerCarouselStoryblok }) => {
    return (
        <div>
            {blok.content.map((item, index) => (
                <Gartner key={index} blok={item} />
            ))}
        </div>
    );
}

export default GartnerCarousel;
