'use client';
import DynamicComponentLoader from 'app/DynamicComponentLoader';
import { motion } from 'framer-motion';
import { BulletCardSectionStoryblok } from 'generated/storyblok/component.types';

import RichTextBlock from 'components/common/RichTextBlock';
import { Blok } from 'components/storyblok/Type';

import style from './BulletCardSection.module.scss';

const BulletCardSection = ({ blok }: Blok<BulletCardSectionStoryblok>) => (
    <div className={style.wrapper}>
        <div className={style.container}>
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{
                    opacity: [0, 1],
                    transition: {
                        delay: 0 / 5,
                        duration: 0.525,
                        ease: 'easeIn',
                    },
                }}
                viewport={{ once: true }}
            >
                <h2>{blok.title}</h2>
            </motion.div>
            {!!blok.body && (
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{
                        opacity: [0, 1],
                        transition: {
                            delay: 0 / 5,
                            duration: 0.525,
                            ease: 'easeIn',
                        },
                    }}
                    viewport={{ once: true }}
                >
                    <RichTextBlock blok={blok.body} />
                </motion.div>
            )}
            <div className={style['cards-container']}>
                {blok.cards &&
                    blok.cards?.map((card, i) => (
                        <motion.div
                            key={card._uid}
                            initial={{ opacity: 0, scale: 0 }}
                            whileInView={{
                                opacity: [0, 1],
                                scale: [0.75, 1.1, 1],
                                transition: {
                                    delay: i / 5,
                                    duration: 0.525,
                                    ease: 'easeIn',
                                },
                            }}
                            viewport={{ once: true }}
                        >
                            <DynamicComponentLoader blok={card} type={card.component} />
                        </motion.div>
                    ))}
            </div>
            <div>
                {blok.children?.map((blok) => (
                    <motion.div
                        key={blok._uid}
                        initial={{ opacity: 0 }}
                        whileInView={{
                            opacity: [0, 1],
                            transition: {
                                delay: blok.cards?.length + 1 / 5,
                                duration: 0.525,
                                ease: 'easeIn',
                            },
                        }}
                        viewport={{ once: true }}
                    >
                        <DynamicComponentLoader blok={blok} type={blok.component} />
                    </motion.div>
                ))}
            </div>
        </div>
    </div>
);

export default BulletCardSection;
