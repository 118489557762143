import { ISbRichtext, renderRichText } from '@storyblok/react';
import { RichtextStoryblok } from 'generated/storyblok/component.types';
import { DateTime } from 'luxon';
import readingTime from 'reading-time';

export const getFormattedDate = (date: string | undefined) => {
    if (!date) {
        return '';
    }

    return (
        DateTime
            .fromFormat(date, 'yyyy-MM-dd HH:mm', { zone: 'local', locale: 'sv' })
            .toFormat('d MMM, yyyy')
            .toUpperCase().replace('.', '')
    )
}

export const getReadTime = (content: ISbRichtext, estimate: boolean) => {
    const minutes = Math.ceil(readingTime(renderRichText(content)).minutes)

    return minutes > 1 ? `${estimate ? 'Ca' : ''} ${minutes} minuters läsning` : `${estimate ? 'Ca' : ''} 1 minuts läsning`
}

export const getLast = (array: string[]) => (
    array[array.length - 1]
);

const homeAliases = ['hem', 'home', 'start'];

export const getUrl = (url: string) => {
    if (!url) return '';
    if (homeAliases.includes(url)) return '';

    return url;
};

export const storyblokUrlToInternalUrl = (url?: string) => {
    if (!url) return '';

    return url.replace('https://a.storyblok.com', `${process.env.NEXT_PUBLIC_DOMAIN}/assets`);
}

type RichTextObject = {
    text?: string;
    content?: RichTextObject[];
};

export const textFromRichText = (obj: RichTextObject): string => {
    if (obj.text) {
        return obj.text;
    }

    if (Array.isArray(obj.content)) {
        return obj.content.reduce((acc, curr) => `${acc} ${textFromRichText(curr)}`, '');
    }

    return '';
};

export const getSummary = (content: RichtextStoryblok, length: number) => {
    const text = textFromRichText(content);
    const words = text.split(' ');

    const summary = words.reduce((acc, curr, index) => {
        if (index < length) {
            return `${acc} ${curr}`.trim();
        } else {
            return acc;
        }
    }, '');

    return summary.length < text.length ? `${summary}...` : summary;
}

export const getCssId = (input: string) => (
    input
        .toLowerCase()
        .replace(/^\d/, '_$&')
        .replace(/[^a-z0-9_-]/g, '-')
);

export type ShallowStory = { full_slug?: string, published_at?: string, created_at?: string };

export const isoToLocalized = (iso: string) => DateTime.fromFormat(iso, 'yyyy-mm-dd hh:mm').setLocale('sv').toISO();

export const removeUnserializableFields = <T>(item: T) => JSON.parse(JSON.stringify(item)) as T;

export const getImageDimensions = (url: string) => {
    const parsedDimensions = url.match(/\/(\d+)x(\d+)\//);

    return [parseInt(parsedDimensions[1]), parseInt(parsedDimensions[2])];
};

export const getSlug = (slug: string[]) => {
    const categories = ['growth-insights', 'case'];
    const lastSlug = slug[slug.length - 1];

    if (categories.includes(lastSlug)) return lastSlug + '/';

    return slug.join('/');
};
