import { ISbRichtext } from '@storyblok/react';
import { AssetStoryblok, HeroImageStoryblok } from 'generated/storyblok/component.types';

import ImageAsset from 'components/common/ImageAsset';
import RichTextBlock from 'components/common/RichTextBlock';

import style from './HeroImage.module.scss';

const HeroImageBlok = ({ blok }: { blok: HeroImageStoryblok }) => {
    return <HeroImage image={blok.image} title={blok.title} body={blok.body} />
}

export const HeroImage = ({ image, body, title }: { image: AssetStoryblok, title: string, body?: ISbRichtext }) => {
    return (
        <div className={style['hero-image']}>
            <ImageAsset className={style.image} priority image={image} height={1080} width={1920} />
            <div className={style.overlay}>
                <h1 className={style.title}>
                    {title}
                </h1>
                <RichTextBlock blok={body} className={style.body} />
            </div>
        </div>
    );
};

export default HeroImageBlok;
