import { ReactNode } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { motion } from 'framer-motion';
import { SubmittedConfirmationStoryblok } from 'generated/storyblok/component.types';

import RichTextBlock from 'components/common/RichTextBlock';

import style from './QuestionSubmitted.module.scss';

export const QuestionSubmitted = ({
    blok,
    children
}: {
    blok: SubmittedConfirmationStoryblok,
    children: ReactNode
}) => {
    return (
        <>
            <motion.div
                className={style['animated-sent']}
                initial={{ opacity: 1, y: '-10%' }}
                animate={{
                    opacity: 0,
                    x: '5%',
                }}
                transition={{
                    delay: 2,
                    duration: .3,
                }}
            >
                <SendIcon fontSize="large" />
                Skickat!
            </motion.div>
            <motion.div
                initial={{ opacity: 0, transform: 'translateY(100px)' }}
                animate={{ opacity: 1, transform: 'translateY(0)' }}
                transition={{ duration: 1, ease: 'easeInOut', delay: 2 }}
                className={style['question-submitted']}
            >
                <h2 className={style.title}>{blok.title}</h2>
                <RichTextBlock blok={blok.body} className={style.body} />
                <div className={style['button-container']}>
                    {children}
                </div>
            </motion.div>
        </>
    );
}