'use client';
import { useState } from 'react';
import { LinkedIn } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import InstagramIcon from '@mui/icons-material/Instagram';
import MenuIcon from '@mui/icons-material/Menu';
import { AssetStoryblok, HeaderStoryblok, MenuItemStoryblok } from 'generated/storyblok/component.types';
import { getUrl, storyblokUrlToInternalUrl } from 'helpers/storyblok.helper';
import Image from 'next/image';
import Link from 'next/link';

import style from './Header.module.scss';

const isActive = (menuItem: MenuItemStoryblok, slug: string[]) => {
    if (slug === undefined && menuItem.link.cached_url === '') {
        return true;
    }

    if (slug?.some((url) => `${url}/` === getUrl(menuItem.link.cached_url))) {
        return true;
    }

    return slug?.some((url) => url === getUrl(menuItem.link.cached_url));
};

const Logotype = ({ image }: { image: AssetStoryblok }) => {
    if (!image) return null;

    return <Image src={storyblokUrlToInternalUrl(image.filename)} priority alt="NetConsult logo" width={180} height={36} />;
};

const Menu = ({ items, slug }: { items: MenuItemStoryblok[]; slug?: string[] }) => {
    return (
        <nav className={style['desktop-wrapper']}>
            {items.map((menuItem, index) => {
                const url = getUrl(menuItem.link.cached_url);

                return (
                    <Link key={index} href={`/${url}`} className={(style[menuItem.variant] || '') + ' ' + (isActive(menuItem, slug) ? style.active : '')}>
                        {menuItem.name}
                    </Link>
                );
            })}
        </nav>
    );
};

const MobileMenu = ({ items }: { items: MenuItemStoryblok[]; slug?: string[]; }) => {
    const [drawerState, setDrawerState] = useState(false);

    const toggleDrawer = () => {
        setDrawerState(!drawerState);
    };

    return (
        <>
            <div className={`${style.cover} ${drawerState ? '' : style['hidden']}`} />
            <nav className={style['mobile-wrapper']}>
                <button onClick={toggleDrawer} aria-label="open hamburger-menu">
                    <MenuIcon className={style.open} />
                </button>

                <div className={`${style['mobile-drawer']} ${drawerState ? style['grid'] : style['hidden']}`}>
                    <button onClick={toggleDrawer} aria-label="close menu">
                        <CloseIcon className={style.close} />
                    </button>
                    {items
                        .map((menuItem) => {
                            const url = getUrl(menuItem.link.cached_url);

                            return (
                                <Link key={menuItem._uid} href={`/${url}`}>
                                    {menuItem.name}
                                </Link>
                            );
                        })
                    }
                    <div className={style['colored-line-break']} />
                    <a href="https://www.instagram.com/netconsultab/" className={style.external}><InstagramIcon/>Instagram</a>
                    <a href="https://www.linkedin.com/company/netconsultab/" className={style.external}><LinkedIn/>LinkedIn</a>
                </div>
            </nav>
        </>
    );
}

export const Header = ({ logotype, main_menu, slug }: HeaderStoryblok & { slug?: string[] }) => (
    <>
        <div className={style.cover} />
        <div className={style.mainHeader}>
            <div className={style.container}>
                <Link href="/">
                    <Logotype image={logotype} />
                </Link>
                <MobileMenu items={main_menu} slug={slug} />
                <Menu items={main_menu} slug={slug} />
            </div>
        </div>
    </>
);
