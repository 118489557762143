import { ReactNode } from 'react';
import { motion } from 'framer-motion';
import { RichtextStoryblok } from 'generated/storyblok/component.types';

import RichTextBlock from 'components/common/RichTextBlock';
import { AnswerType, StarContainer } from 'components/storyblok/home/Questionnaire/StarContainer';

import style from './QuestionCta.module.scss';

export const QuestionCta = ({
    title,
    body,
    children,
    answers,
    isFadingOut,
}: {
    answers: AnswerType[];
    title: string;
    body: RichtextStoryblok;
    isFadingOut: boolean;
    children: ReactNode;
}) => {
    const correctAnswersCount = answers.filter(answer => answer.correct).length;
    const totalAnswers = answers.length;

    return (
        <>
            <motion.div
                className={style['animated-results']}
                initial={{ opacity: 1, position: 'absolute', left: '50%', y: '40%', x: '-50%' }}
                animate={{
                    opacity: 0,
                    y: '50%',
                }}
                transition={{
                    delay: 3,
                    duration: .7,
                }}
            >
                Resultat
                <StarContainer style={style} answers={answers} />
                <p>
                    {correctAnswersCount} av {totalAnswers} rätt!
                </p>
            </motion.div>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 3.5, duration: 1 }}
                className={`${style.cta} ${isFadingOut ? style['fade-out'] : ''}`}
            >
                <div className={style['column-left']}>
                    <div>
                        Resultat:
                    </div>
                    <StarContainer style={style} answers={answers} />
                    {correctAnswersCount} av {totalAnswers} rätt
                </div>

                <motion.div className={style['column-right']}>
                    <h2 className={style.title}>{title}</h2>
                    <RichTextBlock blok={body} />
                    <div className={style['button-container']}>
                        {children}
                    </div>
                </motion.div>
            </motion.div>
        </>
    );
};
