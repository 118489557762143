'use client';
import { useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { GetArticlesGqlDocument, GetArticlesGqlQuery, GrowthinsightItem } from 'generated/graphql';

import { getStoriesGql } from 'components/storyblok/getStoriesGql';
import Article from 'components/storyblok/home/Articles/Article';
import { Pagination } from 'components/storyblok/Pagination';

import style from './Articles.module.scss';

interface ArticleListProps {
    perPage: number,
    showMore?: boolean,
    showMoreTitle?: string,
    showMoreText?: string,
    showMoreLink?: string,
    serverLoadedArticles: GrowthinsightItem[],
    articlesTotal: number,
    hidePagination?: boolean,
};

const ArticleList = ({ showMore, showMoreTitle, showMoreText, showMoreLink, perPage, serverLoadedArticles, articlesTotal, hidePagination = false, }: ArticleListProps) => {
    const [articles, setArticles] = useState<GrowthinsightItem[]>(serverLoadedArticles);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const isAll = articlesTotal <= perPage * currentPage;
    const showPagination = !hidePagination && !isAll;

    const onClick = async () => {
        setIsLoading(true);

        const nextPage = currentPage + 1;
        const result = await getStoriesGql<GetArticlesGqlQuery>({
            query: GetArticlesGqlDocument,
            per_page: perPage,
            page: nextPage
        });

        setCurrentPage(nextPage);
        setArticles((prev) => (prev.concat(result.data.GrowthinsightItems.items as GrowthinsightItem[])));

        setIsLoading(false);
    };

    return (
        <>
            <div className={style['articles-list']}>
                {articles.map(({ full_slug, content }) => (
                    <Article
                        key={content._uid}
                        full_slug={full_slug}
                        content={content}
                    />
                ))}
                {showMore &&
                    <ShowMore
                        showMoreText={showMoreText}
                        showMoreTitle={showMoreTitle}
                        showMoreLink={showMoreLink}
                    />
                }
            </div>
            {showPagination && <Pagination isLoading={isLoading} onClick={onClick} />}
        </>
    );
}

export const ShowMore = ({
    showMoreTitle,
    showMoreText,
    showMoreLink,
}: {
    showMoreTitle: string,
    showMoreText: string,
    showMoreLink: string,
}) => (
    <div className={style['show-more']}>
        <h3>{showMoreTitle}</h3>
        <div className={style['container']}>
            <a href={showMoreLink} aria-label={showMoreText}>
                {showMoreText}
            </a>
            <ArrowForwardIcon aria-hidden="true" />
        </div>
    </div>
)

export default ArticleList;
