import { motion } from 'framer-motion';
import { OptionStoryblok, QuestionStoryblok } from 'generated/storyblok/component.types';

import { AnswerType, StarContainer } from 'components/storyblok/home/Questionnaire/StarContainer';

import style from './QuestionForm.module.scss';

export const QuestionForm = ({
    handleNext,
    question,
    answers,
}: {
    handleNext: (answer: AnswerType) => void;
    question: QuestionStoryblok,
    answers: AnswerType[],
}) => {
    const handleAnswer = (answer: number) => {
        handleNext({
            value: question.options[answer].text,
            correct: answer === parseInt(question.correct_answer_index)
        });
    }

    return (
        <div className={style.form}>
            <motion.div
                initial={{ opacity: 0, y: 20, scale: 0 }}
                whileInView={{ opacity: 1, y: 0, scale: 1, transition: { delay: 0, duration: 0.5, ease: 'easeInOut' } }}
                viewport={{ once: true }}
            >
                <motion.h2
                    initial={{ y: 150 }}
                    whileInView={{ y: 0 }}
                    transition={{ delay: 1.5, duration: 0.6 }}
                    className={style.title}
                    viewport={{ once: true }}
                >
                    {question.question}
                </motion.h2>
                <motion.div
                    className={`${style['button-container']} quiz-button-container`}
                    initial={{ opacity: 0, y: 10 }}
                    whileInView={{ opacity: 1, y: 0, transition: { delay: 2.5, duration: 0.5 } }}
                    viewport={{ once: true }}
                >
                    {question.options.map((option: OptionStoryblok, index: number) => (
                        <button
                            className={`${style.button} quiz-button`}
                            key={index}
                            onClick={() => handleAnswer(index)}
                        >
                            {option.text}
                        </button>
                    ))}
                </motion.div>
            </motion.div>
            {answers.length > 0 && (
                <div className={style.children}>
                    <StarContainer style={style} answers={answers} />
                </div>
            )}
        </div>

    );
}
