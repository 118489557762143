'use client';
import { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { EmbeddedVideoStoryblok } from 'generated/storyblok/component.types';

const EmbeddedVideo = ({ blok }: { blok: EmbeddedVideoStoryblok }) => {
    const isDesktop = useMediaQuery('(min-width: 600px)');

    useEffect(() => {
        const iframe = document.createElement('iframe');
        iframe.src = blok.url?.cached_url;
        iframe.width = '100%';
        iframe.height = isDesktop ? '468px' : '263px';
        iframe.style.padding = '20px 0';
        iframe.style.border = 'none';

        const container = document.querySelector('.embedded-video');

        if (container) {
            container.appendChild(iframe);
        }

        return () => {
            if (container) {
                container.removeChild(iframe);
            }
        };
    });

    return (
        <div>
            <div className="embedded-video" />
        </div>
    );
}

export default EmbeddedVideo;
