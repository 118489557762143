'use client';

import React from 'react';

import style from './ContactUsDialog.module.scss';

type ErrorMessageProps = {
    onRetry: () => void;
}

export const ErrorMessage = ({ onRetry }: ErrorMessageProps) => (
    <div className={style.confirmationContainer}>
        <div className={style.confirmationContent}>
            <p className={style.confirmationHeader}>
                Något gick fel.
            </p>
            <p>
                Vi kunde för tillfället inte behandla din förfrågan.<br />
                Kontakta oss direkt på <a href="mailto:info@netconsult.se">info@netconsult.se</a> eller försök igen senare.
            </p>
            <button className={style.button} onClick={onRetry}>
                Försök igen
            </button>
        </div>
    </div>
);
