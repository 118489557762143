import CheckIcon from '@mui/icons-material/Check';
import MinimizeIcon from '@mui/icons-material/Minimize';
import StarIcon from '@mui/icons-material/Star';

export type AnswerType = {
    value: string;
    correct: boolean;
}

export const AnswerIcon = ({
    correct,
    checkMark = false,
    style,
}: {
    correct: boolean,
    checkMark?: boolean,
    style: Record<string, string>
}) => {
    if (!correct) return (
        <MinimizeIcon className={style.incorrect} fontSize="inherit" color="inherit" />
    );

    if (checkMark) return (
        <CheckIcon className={style.check} fontSize="inherit" color="inherit" />
    );

    return (
        <StarIcon className={style.correct} fontSize="inherit" color="inherit" />
    );
}

export const StarContainer = ({
    answers,
    style,
}: {
    answers: AnswerType[],
    style: Record<string, string>
}) => (
    <div className={style['star-container']} style={{ display: 'flex', alignItems: 'center' }}>
        {answers.map((answer, index) => {
            if (!answer.correct) return;

            return (
                <AnswerIcon style={style} key={index} correct={answer.correct} />
            );
        })}
    </div>
);
