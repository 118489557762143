import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { recaptchaVerify } from 'recaptcha/verify.action';

export const useRecaptcha = (context: string, scoreLimit: number) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const verify = async () => {
        const recaptcha = await executeRecaptcha(context);

        const { success, score = 0 } = await recaptchaVerify(recaptcha);

        return success && score > scoreLimit;
    }

    return {
        verify,
    }
}
