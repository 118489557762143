import { ReactNode } from 'react';
import { motion } from 'framer-motion';
import { RichtextStoryblok } from 'generated/storyblok/component.types';

import RichTextBlock from 'components/common/RichTextBlock';
import { AnswerIcon, AnswerType, StarContainer } from 'components/storyblok/home/Questionnaire/StarContainer';

import style from './QuestionResults.module.scss';

export const QuestionResult = ({
    body,
    title,
    answers,
    children,
}: {
    title: string;
    body: RichtextStoryblok;
    answers: AnswerType[];
    children: ReactNode
}) => {
    const latestAnswer = answers[answers.length - 1];

    const isMobile = window.innerWidth < 900;

    return (
        <div className={style.results}>
            <div className={style.container}>
                <h2 className={style.title}>{title}</h2>
                <motion.div
                    className={style.explanation}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                >
                    <motion.div
                        className={style['latest-answer']}
                        initial={{
                            opacity: 1,
                            position:'relative',
                            scale: 1.1,
                            left: '50%',
                            y: '10%',
                            x: '-50%'
                        }}
                        animate={{
                            opacity: isMobile ? 0 : 1,
                            position: isMobile ? 'absolute' : 'relative',
                            scale: 1,
                            x: isMobile ? '-50%' : 0,
                            y: 0,
                            left: isMobile ? '50%' : 0,
                        }}
                        transition={{ type: 'spring', stiffness: 100, damping: 20, delay: 3.5 }}
                    >
                        <h3>Du svarade:</h3>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 1 }}
                        >
                            {latestAnswer.value}
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 2 }}
                            className={style.answer}
                        >
                            <h3>
                                {latestAnswer.correct ? 'Det är rätt!' : 'Det är fel!'}
                            </h3>
                            <AnswerIcon style={style} checkMark correct={latestAnswer.correct} />
                        </motion.div>
                    </motion.div>
                    <motion.div
                        className={style.body}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 3.8 }}
                    >
                        <RichTextBlock blok={body} />
                        <div className={style['children']}>
                            <StarContainer style={style} answers={answers} />
                            {children}
                        </div>
                    </motion.div>
                </motion.div>
            </div>
        </div>
    );
};
