import DynamicComponentLoader from 'app/DynamicComponentLoader';
import { PageStoryblok } from 'generated/storyblok/component.types';

import Content from 'components/common/Page/Content';
import PageComponent from 'components/common/Page/Page';
import RichTextBlock from 'components/common/RichTextBlock';
import { HeroImage } from 'components/storyblok/HeroImage/HeroImage';

import style from './Page.module.scss';

const Page = ({ blok }: { blok: PageStoryblok }) => (
    <PageComponent>
        <HeroImage image={blok.image} body={blok.hero_body} title={blok.title} />
        <Content className={style.content}>
            <RichTextBlock blok={blok.content} />
        </Content>
        {blok.children?.map((nestedBlok) => (
            <DynamicComponentLoader blok={nestedBlok} type={nestedBlok.component} key={nestedBlok._uid} />
        ))}
    </PageComponent>
);

export default Page;
