'use client';
import { useEffect } from 'react';
import { getCssId } from 'helpers/storyblok.helper';

import style from '../storyblok/Blok/Blok.module.scss';

const MeetingScheduler = ({ maxWidth, title }: { maxWidth: string, title?: string }) => {

    const getMaxWidthStyle = (maxWidth: string) => {
        switch (maxWidth) {
            case 'default':
                return style.default;
            case 'slim':
                return style.slim;
            case 'wide':
                return style.wide;
            case 'center':
                return style.center;
            default:
                return '';
        }
    }

    useEffect(() => {
        const iframe = document.createElement('iframe');
        iframe.src = 'https://meetings.hubspot.com/patrik-jensen/boka-mote-med-saljrepresentant?embed=true';
        iframe.width = '100%';
        iframe.height = '710px';
        iframe.style.paddingTop = '20px';
        iframe.style.border = 'none';

        const container = document.querySelector('.meetings-iframe-container');
        if (container) {
            container.appendChild(iframe);
        }

        const script = document.createElement('script');
        script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
            if (container) {
                container.removeChild(iframe);
            }
        };
    }, []);

    return (
        <div className={`${style.scheduler} ${getMaxWidthStyle(maxWidth)}`}>
            <h2 id={getCssId('Boka ett möte')}>{title || 'Boka ett möte'}</h2>
            <div className="meetings-iframe-container" />
        </div>
    );
};

export default MeetingScheduler;
