import Image from 'next/image';

import style from './ContactUsDialog.module.scss';


export const ContactPortrait = () => (
    <Image
        src="/images/netconsult-contact-image.png"
        alt="Christofer hjälper dig med dina frågor"
        width={100}
        height={100}
        className={style.img}
    />
);
