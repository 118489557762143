import { ComponentType } from 'react';
import dynamic from 'next/dynamic';
import { StoryblokStory } from 'storyblok-generate-ts';

// Manually import components above fold for LCP
import CaseHome from 'components/storyblok/Case/HomePage';
import GrowthInsightCatalog from 'components/storyblok/GrowthInsights/HomePage';
import HeroCards from 'components/storyblok/HeroCards/HeroCards';
import HeroImage from 'components/storyblok/HeroImage/HeroImage';
import GartnerCarousel from 'components/storyblok/home/GartnerCarousel/GartnerCarousel';
import HeroVideo from 'components/storyblok/home/HeroVideo/HeroVideo';
import LandingPage from 'components/storyblok/LandingPage';
import Page from 'components/storyblok/Page';

const components = {
    page: Page,
    growth_insight_catalog: GrowthInsightCatalog,
    growth_insight_home: GrowthInsightCatalog,
    case_home: CaseHome,
    landing_page: LandingPage,
    hero_video: HeroVideo,
    hero_image: HeroImage,
    hero_cards: HeroCards,
    gartner_carousel: GartnerCarousel,
    questionnaire: dynamic(() => import('components/storyblok/home/Questionnaire/Questionnaire')),
    home_page: dynamic(() =>  import('components/storyblok/HomePage')),
    textblock: dynamic(() => import('components/common/TextBlock')),
    config: dynamic(() => import('components/storyblok/Config')),
    button: dynamic(() => import('components/common/Button')),
    meeting_scheduler_button: dynamic(() => import('components/common/MeetingSchedulerButton')),
    hero_section: dynamic(() => import('components/storyblok/Generic/HeroSection/HeroSection')),
    content_section: dynamic(() => import('components/storyblok/Generic/ContentSection/ContentSection')),
    list_section: dynamic(() => import('components/storyblok/Generic/ListSection/ListSection')),
    card_section: dynamic(() => import('components/storyblok/Generic/CardSection/CardSection')),
    link_buttons: dynamic(() => import('components/storyblok/Generic/LinkButtons/LinkButtons')),
    big_hook: dynamic(() => import('components/storyblok/home/BigHook')),
    small_hook: dynamic(() => import('components/storyblok/home/SmallHook')),
    columns: dynamic(() => import('components/common/Columns/Columns')),
    highlights: dynamic(() => import('components/storyblok/home/Highlights/Highlights')),
    timeline: dynamic(() => import('components/storyblok/home/Timeline/Timeline')),
    articles: dynamic(() => import('components/storyblok/home/Articles/Articles')),
    author_summary: dynamic(() => import('components/storyblok/GrowthInsights/AuthorSummary')),
    cases_preview: dynamic(() => import('components/storyblok/home/Cases/Cases')),
    headshots: dynamic(() => import('components/storyblok/home/Headshots/Headshots')),
    growth_insight: dynamic(() => import('components/storyblok/GrowthInsights/SinglePage')),
    related_articles: dynamic(() => import('components/storyblok/GrowthInsights/RelatedArticlesBlok')),
    highlight_container: dynamic(() => import('components/common/HighlightContainer')),
    case: dynamic(() => import('components/storyblok/Case/SinglePage')),
    meeting_scheduler: dynamic(() => import('components/storyblok/MeetingSchedulerBlok')),
    embedded_video: dynamic(() => import('components/common/EmbeddedVideo')),
    hero_page: dynamic(() => import('components/storyblok/HeroPage/HeroPage')),
    image_and_text_side_by_side: dynamic(() => import('components/storyblok/ImageAndTextSideBySide/ImageAndTextSideBySide')),
    bullet_card_section: dynamic(() => import('components/storyblok/cards/sections/BulletCardSection')),
    bullet_card: dynamic(() => import('components/storyblok/cards/cards/BulletCard')),
    show_more: dynamic(() => import('components/storyblok/ShowMore/ShowMore')),
    text_intro_section: dynamic(() => import('components/storyblok/TextIntroSection/TextIntroSection')),
    two_columns: dynamic(() => import('components/storyblok/TwoColumns/TwoColumns')),
    hero_layout: dynamic(() => import('components/storyblok/HeroLayout/HeroLayout')),
    hero_content: dynamic(() => import('components/storyblok/HeroContent/HeroContent')),
    contact_us_dialog_button: dynamic(() => import('components/storyblok/Dialog/ContactUsDialogButton')),
    stylish_hr: dynamic(() => import('components/storyblok/StylishHr/StylishHr')),
};

export type BlokComponentProps = {
    blok: StoryblokStory<unknown>;
};

export const getComponent = (component: string) => {
    const importedComponent = components[component];

    if (!importedComponent) return undefined;

    return importedComponent as ComponentType<BlokComponentProps>;
};
