import DynamicComponentLoader from 'app/DynamicComponentLoader';
import { GetCasesGqlDocument, GetCasesGqlQuery } from 'generated/graphql';
import { CaseHomeStoryblok } from 'generated/storyblok/component.types';
import { removeUnserializableFields } from 'helpers/storyblok.helper';

import Page from 'components/common/Page/Page';
import RichTextBlock from 'components/common/RichTextBlock';
import { getStoriesGql } from 'components/storyblok/getStoriesGql';
import { HeroVideoGeneric } from 'components/storyblok/Hero/HeroVideoGeneric';
import CasePreview from 'components/storyblok/home/Cases/CasePreview';

import style from './HomePage.module.scss';

const HomePage = async ({ blok }: { blok: CaseHomeStoryblok }) => {
    const baseQuantity = 6;

    const { data } = await getStoriesGql<GetCasesGqlQuery>({
        query: GetCasesGqlDocument,
        per_page: baseQuantity,
        page: 1
    });

    return (
        <Page>
            <HeroVideoGeneric style={style} fallbackImage={blok.fallback_image} video={blok.video}>
                <div className={style.text}>
                    <h1 className={style.title}>{blok.title}</h1>
                    <RichTextBlock className={style.body} blok={blok.content} />
                </div>
            </HeroVideoGeneric>
            <div className={style['cases-wrapper']}>
                <CasePreview
                    serverLoadedCases={removeUnserializableFields(data.CaseItems.items)}
                    casesTotal={data.CaseItems.total}
                    perPage={baseQuantity}
                />
            </div>
            {blok.children?.map((nestedBlok) => (
                <DynamicComponentLoader blok={nestedBlok} type={nestedBlok.component} key={nestedBlok._uid} />
            ))}
        </Page>
    );
};

export default HomePage;
