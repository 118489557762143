import { HeroVideoStoryblok } from 'generated/storyblok/component.types';
import { storyblokUrlToInternalUrl } from 'helpers/storyblok.helper';

import style from './HeroVideo.module.scss';

const HeroVideo = ({ blok }: { blok: HeroVideoStoryblok }) => {
    return (
        <div className={style['hero-video']}>
            <div className={style.overlay}>
                <h1 className={style.title}>{blok.title}</h1>
                <p>{blok.subtitle}</p>
            </div>
            <video
                className={style.video}
                poster={`${blok.fallback_image.filename}/m/`}
                playsInline
                autoPlay
                muted
                loop
            >
                <source src={storyblokUrlToInternalUrl(blok.video?.filename)} type="video/webm" />
                <source src={storyblokUrlToInternalUrl(blok.video2?.filename)} type="video/mov" />
                <source src={storyblokUrlToInternalUrl(blok.video3?.filename)} type="video/mp4" />
            </video>
        </div>
    );
};

export default HeroVideo;
