'use client';
import { useRef, useState } from 'react';

import { ContactPortrait } from 'components/common/ContactUsDialog/ContactPortrait';
import { CtaData } from 'components/common/ContactUsDialog/CtaData.type';

import style from './ContactUsDialog.module.scss'

type ContactWithPhoneProps = {
    toggleStep: () => void;
    onSubmit: (data: CtaData) => void;
}

const testPhone = (value: string = '') => /^(\+46|07)\d{8}$|^(\+46|07)[\d\s-]{8,11}\d$/.test(value);

const ContactWithPhone = ({
    toggleStep,
    onSubmit,
}: ContactWithPhoneProps) => {
    const ref = useRef<HTMLInputElement>();
    const [ isValid, setIsValid ] = useState(false);

    const onChange = () => {
        const phone = ref.current.value;

        setIsValid(testPhone(phone));
    }

    const onSubmitPhone = (event) => {
        event.preventDefault();

        const phone = ref.current.value;

        setIsValid(testPhone(phone));

        if (isValid) {
            onSubmit({ phone });
        }
    }

    return (
        <>
            <div className={style.container}>
                <ContactPortrait />
                <p>
                    Mitt namn är Christofer och jag hjälper dig att komma vidare med era frågor.
                    Jag ringer dig inom en arbetsdag från <span>073-541 23 33</span>
                </p>
            </div>
            <form onSubmit={onSubmitPhone} className={style.buttonContainer}>
                <input
                    type="tel"
                    name="phone"
                    placeholder="07xx-xx xx xx"
                    ref={ref}
                    required
                    onChange={onChange}
                />
                <button className={style.button} disabled={!isValid} type="submit">Ring mig</button>
            </form>
            <button className={style.contactLink} onClick={toggleStep}>
                Kontakta mig via e-post istället
            </button>
        </>
    );
}

export default ContactWithPhone;
