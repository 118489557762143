'use client';
import { useRef, useState } from 'react';

import { CtaData } from 'components/common/ContactUsDialog/CtaData.type';

import style from './ContactUsDialog.module.scss'

type ContactWithEmailProps = {
    toggleStep: () => void;
    onSubmit: (data: CtaData) => void;
}

const testEmail = (value: string = '') => /^\+?[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);

const ContactWithEmail = ({
    toggleStep,
    onSubmit,
}: ContactWithEmailProps) => {
    const ref = useRef<HTMLInputElement>();
    const [ isValid, setIsValid ] = useState(false);

    const onChange = () => {
        const phone = ref.current.value;

        setIsValid(testEmail(phone));
    }

    const onSubmitEmail = (event) => {
        event.preventDefault();

        const email = ref.current.value;

        setIsValid(testEmail(email));

        if (isValid) {
            onSubmit({ email });
        }
    }

    return (
        <>
            <div className={style.container}>
                <p>Vi hör av oss via e-post för att hjälpa dig med dina frågor.</p>
            </div>
            <form onSubmit={onSubmitEmail} className={style.buttonContainer}>
                <input
                    type="email"
                    name="email"
                    placeholder="E-post"
                    ref={ref}
                    required
                    lang="sv"
                    onChange={onChange}
                />
                <button
                    className={style.button}
                    type="submit"
                    disabled={!isValid}
                >Maila mig</button>
            </form>
            <button className={style.contactLink} onClick={toggleStep}>
                Ring mig istället
            </button>
        </>
    );
}

export default ContactWithEmail;
