'use client';

import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { motion } from 'framer-motion';
import { usePathname } from 'next/navigation';
import { useRecaptcha } from 'recaptcha/useRecaptcha.hook';

import Confirmation from 'components/common/ContactUsDialog/Confirmation';
import ContactWithEmail from 'components/common/ContactUsDialog/ContactWithEmail';
import ContactWithPhone from 'components/common/ContactUsDialog/ContactWithPhone';
import { CtaData } from 'components/common/ContactUsDialog/CtaData.type';
import { ErrorMessage } from 'components/common/ContactUsDialog/Error';
import { makeCtaGetAction } from 'components/common/ContactUsDialog/makeCtaGetAction';

import style from './ContactUsDialog.module.scss';

type ContactUsDialogProps = {
    title: string;
    handleClose: () => void;
}

const useLoading = () => {
    const [ loading, setLoading ] = useState(false);

    return {
        loading,
        setLoading,
    }
}

const ContactUsDialog = ({
    title,
    handleClose,
}: ContactUsDialogProps) => {
    const [currentStep, setCurrentStep] = useState<'phone' | 'email' | 'confirmation' | 'error'>('phone');
    const currentPagePath = usePathname();
    const { loading, setLoading } = useLoading();

    const { verify } = useRecaptcha('cta_contact_us', 0.7);

    const stepToPhoneStep = () => {
        setCurrentStep('phone');
    };

    const stepToEmailStep = () => {
        setCurrentStep('email');
    };

    const stepToConfirmationStep = () => {
        setCurrentStep('confirmation');
    }

    const goToErrorStep = () => {
        setCurrentStep('error');
    }

    const onSubmit = async ({ phone = 'Ingen angiven', email = 'Ingen angiven' }: CtaData) => {
        setLoading(true);

        const isVerified = await verify();

        if (isVerified) {
            try {
                const result = await makeCtaGetAction({ phone, email, landingpage: currentPagePath });

                setLoading(false);

                if (result) {
                    stepToConfirmationStep();
                } else {
                    goToErrorStep();
                }
            } catch (e) {
                goToErrorStep();
            }
        }

        setLoading(false);
    }

    return (
        <div className={style.wrapper}>
            <IconButton className={style.close} onClick={handleClose}>
                <CloseIcon fontSize="inherit" />
            </IconButton>
            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                {{
                    loading: <CircularProgress sx={{ color: '#000 !important' }} />,
                    confirmation: <Confirmation handleClose={handleClose} />,
                    error: <ErrorMessage onRetry={stepToPhoneStep}/>,
                }[loading ? 'loading' : currentStep] || (
                    <Stack sx={{ flexGrow: 1, height: '100%' }}>
                        <div className={style.header}>
                            <h2>{title}</h2>
                        </div>
                        {currentStep === 'phone' && (
                            <Stack
                                sx={{
                                    height: '100%',
                                }}
                                component={motion.div}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                <ContactWithPhone
                                    toggleStep={stepToEmailStep}
                                    onSubmit={onSubmit}
                                />
                            </Stack>
                        )}
                        {currentStep === 'email' && (
                            <Stack
                                sx={{
                                    height: '100%',
                                }}
                                component={motion.div}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                <ContactWithEmail
                                    toggleStep={stepToPhoneStep}
                                    onSubmit={onSubmit}
                                />
                            </Stack>
                        )}
                    </Stack>
                )}
            </Stack>
        </div>
    );
}

export default ContactUsDialog;
