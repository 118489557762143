import { HTMLProps, PropsWithChildren } from 'react';

export type ContentProps = { title?: string } & PropsWithChildren & HTMLProps<HTMLElement>

const Content = ({ title, children, ...rest }: ContentProps) => (
    <section {...rest}>
        {title && title !== '' && <h1>{title}</h1>}
        {children}
    </section>
);

export default Content;
