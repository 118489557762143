'use client';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ImageAndTextSideBySideStoryblok } from 'generated/storyblok/component.types';
import { getUrl, storyblokUrlToInternalUrl } from 'helpers/storyblok.helper';
import Link from 'next/link';

import RichTextBlock from 'components/common/RichTextBlock';

import style from './ImageAndTextSideBySide.module.scss';

const useFirstWordBold = (text: string) => {
    const [firstWord, ...rest] = text.split(' ');

    return [
        firstWord,
        rest.join(' '),
    ];
}

const FirstBoldHeader = ({ title }: { title: string }) => {
    const [ header, rest ] = useFirstWordBold(title);

    return <h2>{header} <span style={{ fontWeight: 300 }}>{rest}</span></h2>;
}

type ButtonProps = {
    button?: ImageAndTextSideBySideStoryblok['button'],
    text?: ImageAndTextSideBySideStoryblok['button_text'],
};

const Button = ({ button, text }: ButtonProps) => {
    if (!button || !text) {
        return null;
    }

    const url = getUrl(button.cached_url);

    const href = button.linktype === 'url' ?  button.cached_url : `/${url}`;

    return (
        <Link href={href} className={style.button} target={button.target}>
            {text} <ArrowForwardIcon className={style.icon}/>
        </Link>
    );
}

const ImageAndTextSideBySide = ({ blok }: { blok: ImageAndTextSideBySideStoryblok }) => {
    return (
        <div className={style.section}>
            {blok.image_position === 'left' && (
                <div
                    style={{ backgroundImage: `url('${storyblokUrlToInternalUrl(blok.image.filename)}')` }}
                    className={style.image}
                ></div>
            )}
            <div>
                <div>
                    <FirstBoldHeader title={blok.title} />
                    <RichTextBlock className={style.body} blok={blok.body} />
                    <Button button={blok.button} text={blok.button_text} />
                </div>
            </div>
            {blok.image_position === 'right' && (
                <div
                    style={{ backgroundImage: `url('${storyblokUrlToInternalUrl(blok.image.filename)}')` }}
                    className={style.image}
                ></div>
            )}
        </div>
    );
};

export default ImageAndTextSideBySide;
