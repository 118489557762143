import { CircularProgress } from '@mui/material';

import style from './Pagination.module.scss';

export const Pagination = ({ onClick, isLoading }: { onClick: () => void; isLoading?: boolean }) => {
    if (isLoading) {
        return <Loading />;
    }

    return <FetchMore onClick={onClick} />;
}

const Loading = () => (
    <div className={style.loading} >
        <CircularProgress color="inherit" />
    </div>
);

const FetchMore = ({ onClick }: { onClick: () => void }) => (
    <div className={style['pagination-wrapper']}>
        <button onClick={onClick}>Visa fler</button>
    </div>
);
