'use client';
import { useEffect, useState } from 'react';
import { GetAuthorsByUuidsDocument, GetAuthorsByUuidsQuery } from 'generated/graphql';
import { AuthorStoryblok, GrowthInsightStoryblok } from 'generated/storyblok/component.types';

import style from 'components/common/RelatedArticles/RelatedArticles.module.scss';
import { getStoriesGql } from 'components/storyblok/getStoriesGql';
import Article from 'components/storyblok/home/Articles/Article';

// this solution is a fix for an issue with nested server calls that caused a waterfall of
// server calls during draft mode
export const RelatedArticlesClient = ({ articles, title }: { articles: GrowthInsightStoryblok[], title: string }) => {
    const [ authors, setAuthors ] = useState<AuthorStoryblok[]>([]);

    useEffect(() => {
        const authorUuids = articles.map((article) => article.content.author);

        getStoriesGql<GetAuthorsByUuidsQuery>({
            query: GetAuthorsByUuidsDocument,
            per_page: 4,
            page: 1,
            by_uuids: authorUuids.join(',')
        }).then((res) => setAuthors(res.data.AuthorItems.items as AuthorStoryblok[]));
    }, [articles]);

    return (
        <div className={style['related-articles']}>
            <h2>{title || 'Upptäck fler artiklar som denna'}</h2>
            <div className={style.wrapper}>
                {articles.map((article) => (
                    <Article
                        content={article.content}
                        authors={authors}
                        full_slug={article.full_slug}
                        className={style.article}
                        key={article.uuid}
                        showSummary
                    />
                ))}
            </div>
        </div>
    );
}