import { ReactNode } from 'react';
import { AssetStoryblok } from 'generated/storyblok/component.types';
import { storyblokUrlToInternalUrl } from 'helpers/storyblok.helper';

export const HeroVideoGeneric = ({
    children,
    fallbackImage,
    video,
    video2,
    video3,
    style,
}: {
    children: ReactNode,
    fallbackImage: AssetStoryblok,
    video?: AssetStoryblok,
    video2?: AssetStoryblok,
    video3?: AssetStoryblok,
    style: Record<string, string>
}) => (
    <div className={style.wrapper}>
        <div className={style.hero}>
            <video
                className={style.video}
                poster={`${fallbackImage?.filename}/m/`}
                playsInline
                disablePictureInPicture
                autoPlay
                muted
                loop
            >
                <source src={storyblokUrlToInternalUrl(video?.filename)} type="video/webm" />
                <source src={storyblokUrlToInternalUrl(video2?.filename)} type="video/mov" />
                <source src={storyblokUrlToInternalUrl(video3?.filename)} type="video/mp4" />
            </video>
            <div className={style.overlay}>
                {children}
            </div>
        </div>
    </div>
);
