import { AssetStoryblok } from 'generated/storyblok/component.types';
import { getImageDimensions, storyblokUrlToInternalUrl } from 'helpers/storyblok.helper';
import Image, { ImageProps } from 'next/image';

const ImageAsset = ({ image, priority = false, height, width, className }: { image: AssetStoryblok, priority?: boolean } & Pick<ImageProps, 'width' | 'height' | 'className' >) => {
    if (!image?.filename) return null;

    if (!height || !width) {
        try {
            const [ width, height ] = getImageDimensions(image.filename);

            return (
                <Image
                    priority={priority}
                    src={`${storyblokUrlToInternalUrl(image.filename)}`}
                    alt={image.alt}
                    width={width}
                    height={height}
                    className={className}
                />
            );
        } catch (e) {
            return null;
        }
    }

    return (
        <Image
            priority={priority}
            src={`${storyblokUrlToInternalUrl(image.filename)}`}
            alt={image.alt}
            height={height}
            width={width}
            className={className}
        />
    );
}

export default ImageAsset;
