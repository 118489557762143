import DynamicComponentLoader from 'app/DynamicComponentLoader';
import { GetArticlesGqlDocument, GetArticlesGqlQuery } from 'generated/graphql';
import { GrowthInsightCatalogStoryblok } from 'generated/storyblok/component.types';
import { removeUnserializableFields } from 'helpers/storyblok.helper';

import Page from 'components/common/Page/Page';
import RichTextBlock from 'components/common/RichTextBlock';
import { getStoriesGql } from 'components/storyblok/getStoriesGql';
import { HeroVideoGeneric } from 'components/storyblok/Hero/HeroVideoGeneric';
import ArticleList from 'components/storyblok/home/Articles/ArticleList';

import style from './HomePage.module.scss';

const HomePage = async ({ blok }: { blok: GrowthInsightCatalogStoryblok }) => {
    const baseQuantity = 20;

    const { data } = await getStoriesGql<GetArticlesGqlQuery>({
        query: GetArticlesGqlDocument,
        per_page: baseQuantity,
        page: 1,
    });

    return (
        <Page>
            <HeroVideoGeneric style={style} fallbackImage={blok.fallback_image} video={blok.video} video2={blok?.video2}>
                <div className={style.text}>
                    <h1 className={style.title}>{blok.title}</h1>
                    <RichTextBlock className={style.body} blok={blok.content} />
                </div>
            </HeroVideoGeneric>
            <div className={style['articles-wrapper']}>
                <ArticleList
                    perPage={baseQuantity}
                    serverLoadedArticles={removeUnserializableFields(data.GrowthinsightItems.items)}
                    articlesTotal={data.GrowthinsightItems.total}
                />
            </div>
            {blok.children?.map((nestedBlok) => (
                <DynamicComponentLoader blok={nestedBlok} type={nestedBlok.component} key={nestedBlok._uid} />
            ))}
        </Page>
    );
};

export default HomePage;
