'use client';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { MeetingSchedulerButtonStoryblok } from 'generated/storyblok/component.types';

import MeetingScheduler from 'components/common/MeetingScheduler';

import style from './Common.module.scss';

const MeetingSchedulerButton = ({ blok }: { blok: MeetingSchedulerButtonStoryblok }) => {
    const [show, setShow] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <div id={blok.id} className={style['scheduler-button']} onClick={() => setShow(!show)}>
                <div className={style.icon}><GroupsOutlinedIcon />{blok.title}</div>
                <div className={style.description}>{blok.description}</div>
            </div>
            <Dialog fullScreen={isMobile} maxWidth="xl" open={show} onClose={() => setShow(false)}>
                <div className={style.scheduler}>
                    <MeetingScheduler title={' '} maxWidth={blok.max_width} />
                </div>
                <CloseIcon fontSize="inherit" className={style.close} onClick={() => setShow(false)} />
            </Dialog>
        </>
    )
}

export default MeetingSchedulerButton;
