'use client';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { GartnerStoryblok } from 'generated/storyblok/component.types';
import Link from 'next/link';

import RichTextBlock from 'components/common/RichTextBlock';

import style from './Gartner.module.scss';

const Gartner = ({ blok }: { blok: GartnerStoryblok }) => {
    const [ref, inView] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });

    useEffect(() => {
        if (inView) {
            const element = document.querySelector(`.${style['bar-graph']}`);
            element.classList.add(style.grow);
        }
    }, [inView]);

    return (
        <div className={style['background-wrapper']} style={{
            backgroundImage: `url(${blok.background_image?.filename}/m/)`
        }}>
            <div className={style.wrapper}>
                <div className={style['text']}>
                    <h2>{blok.title}</h2>
                    <RichTextBlock blok={blok.body} className={style.body} />
                </div>
                <div className={style['graph']}>
                    <div ref={ref} className={style['bar-graph']}>
                        {blok.graph.map((bar, index) => (
                            <div
                                key={index}
                                className={style['bar-graph_bar']}
                            >
                                <div
                                    className={style['bar-graph_value']}
                                    style={{ height: `calc(${bar.value}% * 0.75)` }}
                                />
                                <div className={style['bar-graph_title']}>
                                    {bar.title}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={style.link}>
                    <Link href={blok.link.cached_url}>
                        {blok.button_title}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Gartner;
