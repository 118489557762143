import { ISbRichtext } from '@storyblok/react';
import DynamicComponentLoader from 'app/DynamicComponentLoader';
import Link from 'next/link';
import { MARK_LINK, render } from 'storyblok-rich-text-react-renderer';

const RichTextBlock = ({ blok, className }: { blok: ISbRichtext, className?: HTMLElement['className'] }) => (
    <div className={className}>
        {render(blok, {
            defaultBlokResolver: (name, props) => {
                const blok = { ...props, component: name };
                return <DynamicComponentLoader blok={blok} type={blok.component} key={props._uid} />;
            },
            markResolvers: {
                [MARK_LINK]: (children, props) => {
                    const { linktype, href, target } = props;
                    if (linktype === 'email') {
                        return <a href={`mailto:${href}`}>{children}</a>;
                    }
                    if (href.match(/^(https?:)?\/\//)) {
                        return <a href={href} target={target}>{children}</a>;
                    }
                    return <Link href={`${href}${props.anchor || ''}`}>{children}</Link>;
                }
            }
        })}
    </div>
);

export default RichTextBlock;
