import Instagram from '@mui/icons-material/Instagram';
import LinkedIn from '@mui/icons-material/LinkedIn';
import DynamicComponentLoader from 'app/DynamicComponentLoader';
import { HeroCardsStoryblok } from 'generated/storyblok/component.types';
import { storyblokUrlToInternalUrl } from 'helpers/storyblok.helper';

import Page from 'components/common/Page/Page';
import RichTextBlock from 'components/common/RichTextBlock';

import style from './HeroCards.module.scss';

const HeroCards = ({ blok }: { blok: HeroCardsStoryblok }) => (
    <Page>
        <div className={style.wrapper}>
            <div className={style.hero}>
                <video
                    className={style.video}
                    poster={`${blok.fallback_image?.filename}/m/`}
                    playsInline
                    disablePictureInPicture
                    autoPlay
                    muted
                    loop
                >
                    <source src={storyblokUrlToInternalUrl(blok.video?.filename)} type="video/webm" />
                    <source src={storyblokUrlToInternalUrl(blok.video2?.filename)} type="video/mov" />
                    <source src={storyblokUrlToInternalUrl(blok.video3?.filename)} type="video/mp4" />
                </video>
                <div className={style.overlay}>
                    <div className={style.text}>
                        <h1 className={style.title}>{blok.title}</h1>
                        <RichTextBlock className={style.body} blok={blok.body} />
                    </div>
                    <div className={style.cards}>
                        {blok.cards[0].columns?.map((card, i) => {
                            if (i === 2) {
                                return (
                                    <div className={style.card} key={card._uid}>
                                        <h2>{card.title}</h2>
                                        <div className={style.icons}>
                                            <a href="https://www.instagram.com/netconsultab/">
                                                <Instagram fontSize="inherit" />
                                            </a>
                                            <a href="https://www.linkedin.com/company/netconsultab/">
                                                <LinkedIn fontSize="inherit" />
                                            </a>
                                        </div>
                                        <RichTextBlock className={style['card-body']} blok={card.body} />
                                    </div>
                                );
                            }

                            return (
                                <div className={style.card} key={card._uid}>
                                    <h2>{card.title}</h2>
                                    <RichTextBlock className={style['card-body']} blok={card.body} />
                                </div>
                            );
                        })}
                    </div>

                </div>
            </div>
            <div className={style['small-hook']}>
                <div className={style['small-hook-content']}>
                    <h2 className={style.title}>
                        {blok.small_hook[0].title}
                    </h2>
                    <p className={style.body}>
                        {blok.small_hook[0].body}
                    </p>
                </div>
            </div>
            <DynamicComponentLoader type={blok.children[0].component} blok={blok.children[0]} />
        </div>
    </Page>
)

export default HeroCards;
