import { LandingPageStoryblok } from 'generated/storyblok/component.types';

import PageComponent from 'components/common/Page/Page';
import Blok from 'components/storyblok/Blok';

const LandingPage = ({ blok }: { blok: LandingPageStoryblok }) => {
    return (
        <PageComponent>
            <Blok iterativeDiscolored blok={blok} backgroundInterval />
        </PageComponent>
    );
};

export default LandingPage;
