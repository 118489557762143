import { GrowthinsightComponent } from 'generated/graphql';
import { AuthorStoryblok } from 'generated/storyblok/component.types';
import { getFormattedDate, getReadTime, storyblokUrlToInternalUrl } from 'helpers/storyblok.helper';
import Image from 'next/image';
import Link from 'next/link';

import ImageAsset from 'components/common/ImageAsset';
import style from 'components/storyblok/home/Articles/Articles.module.scss';

type ArticleType = {
    content: GrowthinsightComponent;
    authors?: AuthorStoryblok[];
    full_slug: string;
    className?: string;
    showSummary?: boolean;
}

const Article = ({ full_slug, content, authors, className, showSummary = false }: ArticleType) => {
    // resolve_relations is one relation deep and author is at depth 2 in RelatedArticles.
    const author = typeof content.author === 'string'
        ? authors?.find((a) => a.uuid === content.author)
        : content.author;

    return (
        <Link href={`/${full_slug}`} key={content._uid} className={`${style.article} ${className}`}>
            <div className={style.image}>
                <Image src={storyblokUrlToInternalUrl(content.image?.filename)} alt={content.image?.alt} width={300}
                    height={600} />
            </div>
            <div className={style.content}>
                <div className={style['article-title']}>
                    <h4>{content.title}</h4>
                    {showSummary && <p className={style.summary}>{content.related_articles_summary}</p>}
                </div>
                {author?.content && (
                    <div className={style.author}>
                        <ImageAsset priority image={author.content?.image} width={40} height={40} />
                        <div className={style['name-date-wrapper']}>
                            <div className={style.name}>
                                <div>{author.content?.name}</div>
                                <div>{getFormattedDate(content.written_at)}</div>
                            </div>
                            <div>{getReadTime(content.content, false)}</div>
                        </div>
                    </div>
                )}
            </div>
        </Link>
    );
};

export default Article;
