import EastIcon from '@mui/icons-material/East';
import { CaseComponent } from 'generated/graphql';
import { getLast, storyblokUrlToInternalUrl } from 'helpers/storyblok.helper';
import Image from 'next/image';
import Link from 'next/link';

import RichTextBlock from 'components/common/RichTextBlock';

import style from './CasePreview.module.scss';

type CaseProps = {
    full_slug: string;
    content: CaseComponent;
};

const Case = ({ full_slug, content }: CaseProps ) => (
    <div className={style.teaser}>
        <h4 className={style['teaser-title']}>{content.title}</h4>
        <div className={style['teaser-body']}>
            <RichTextBlock blok={content.summary} />
        </div>
        <div className={style['teaser-footer']}>
            <div className={style.logotype}>
                <Image
                    priority
                    src={storyblokUrlToInternalUrl(content.logotype?.filename)}
                    alt={`${getLast(full_slug.split('/'))} logo`}
                    width={100}
                    height={56}
                />
            </div>
            <Link className={style['teaser-button']} href={full_slug}>
                <div className={style['teaser-link']}>
                    {content.button_text || 'Läs mer'}
                    <EastIcon />
                </div>
            </Link>
        </div>
    </div>
)

export default Case;
