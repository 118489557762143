import DynamicComponentLoader from 'app/DynamicComponentLoader';
import { HomePageStoryblok, LandingPageStoryblok } from 'generated/storyblok/component.types';

import style from './Blok.module.scss';

const discoloredBackgrounds = ['articles'];

const Blok = ({ blok, iterativeDiscolored = false }: { blok: HomePageStoryblok | LandingPageStoryblok, backgroundInterval?: boolean, iterativeDiscolored?: boolean }) => {
    const getClassName = (index: number) => {
        if (!iterativeDiscolored) return '';

        return index % 2 === 0 ? style.even : style.odd;
    }

    return (
        <div className={style.wrapper}>
            {blok.children?.map((nestedBlok, index) => {
                const className = [
                    discoloredBackgrounds.includes(nestedBlok.component) ? style['discolored'] : '',
                    getClassName(index),
                ];

                return (
                    <section key={index} className={className.join('')}>
                        <DynamicComponentLoader blok={nestedBlok} type={nestedBlok.component} key={nestedBlok._uid} />
                    </section>
                );
            })}
        </div>
    );
};

export default Blok;
