'use client';
import { useState } from 'react';
import { CaseItem, GetCasesGqlDocument, GetCasesGqlQuery } from 'generated/graphql';

import { getStoriesGql } from 'components/storyblok/getStoriesGql';
import CaseShowcase from 'components/storyblok/home/Cases/CaseShowcase';
import { Pagination } from 'components/storyblok/Pagination';

import style from './CasePreview.module.scss';

type CasePreviewProps  = {
    perPage: number;
    casesTotal: number;
    serverLoadedCases: CaseItem[];
    hidePagination?: boolean;
};

const CasePreview = ({ perPage, serverLoadedCases, casesTotal, hidePagination = false }: CasePreviewProps) => {
    const [cases, setCases] = useState<CaseItem[]>(serverLoadedCases);
    const [currentPage, setCurrentPage] = useState(2);
    const [isAll, setIsAll] = useState(casesTotal <= perPage);

    const onClick = async () => {
        const result = await getStoriesGql<GetCasesGqlQuery>({
            query: GetCasesGqlDocument,
            per_page: perPage,
            page: currentPage,
        });

        setCases((prev) => (prev.concat(result.data.CaseItems.items as CaseItem[])));
        setIsAll(casesTotal <= perPage * currentPage);
        setCurrentPage(currentPage + 1);
    };

    return (
        <>
            <div className={style['teaser-list']}>
                {cases.map(({ full_slug, content }, index) => (
                    <CaseShowcase full_slug={full_slug} content={content} key={index} />
                ))}
            </div>
            {!isAll && !hidePagination && <Pagination onClick={onClick} />}
        </>
    );
}

export default CasePreview;
