'use client';

import { useState } from 'react';
import { QuestionnaireStoryblok, QuestionStoryblok } from 'generated/storyblok/component.types';
import { storyblokUrlToInternalUrl } from 'helpers/storyblok.helper';

import { ContactForm } from 'components/storyblok/home/Questionnaire/ContactForm';
import { QuestionCta } from 'components/storyblok/home/Questionnaire/QuestionCta';
import { QuestionForm } from 'components/storyblok/home/Questionnaire/QuestionForm';
import { QuestionResult } from 'components/storyblok/home/Questionnaire/QuestionResult';
import { QuestionSubmitted } from 'components/storyblok/home/Questionnaire/QuestionSubmitted';
import { AnswerType } from 'components/storyblok/home/Questionnaire/StarContainer';

import style from './Questionnaire.module.scss';

export const QuestionnaireStepper = ({ blok }: { blok: QuestionnaireStoryblok }) => {
    const [step, setStep] = useState(1);
    const [answers, setAnswers] = useState<AnswerType[]>([]);
    const [isFinished, setIsFinished] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [isFadingOut, setIsFadingOut] = useState(false);

    const questionnaireLength = blok.questions.length * 2;

    const handleNext = (answer: AnswerType) => {
        setAnswers([...answers, answer]);
        setStep(step + 1);
    };

    const isQuestion = step % 2 === 1;

    const getQuestion = (step: number): QuestionStoryblok => {
        return blok.questions[Math.floor((step - 1) / 2)];
    };

    if (hasSubmitted) {
        return (
            <QuestionSubmitted blok={blok.submitted_confirmation[0]}>
                <button className={`${style['filled-button']} quiz-reset2`} onClick={() => {
                    setHasSubmitted(false);
                    setIsFinished(false);
                    setIsFadingOut(false);
                    setAnswers([]);
                    setStep(1);
                }}>Börja om</button>
            </QuestionSubmitted>
        );
    }

    if (isFinished) {
        return (
            <ContactForm setHasSubmitted={setHasSubmitted} blok={blok.contact_form[0]}>
                <button type="submit" className={`${style['filled-button']} quiz-submit`}>
                    Skicka in
                </button>
            </ContactForm>
        );
    }

    if (step > questionnaireLength) {
        const handleFinishClick = () => {
            setIsFadingOut(true);
            setTimeout(() => {
                setIsFinished(true);
            }, 1000);
        };

        return (
            <QuestionCta answers={answers} isFadingOut={isFadingOut} title={blok.cta_title} body={blok.cta_body}>
                <button className={`${style['filled-button']} quiz-open-contact-form`} onClick={handleFinishClick}>
                    Ja tack, låt oss pratas vid!
                </button>
                <button className={`${style['hollow-button']} quiz-reset`} onClick={() => {
                    setStep(1);
                    setAnswers([]);
                }}>Börja om</button>
            </QuestionCta>
        )
    }

    if (isQuestion) {
        return (
            <QuestionForm answers={answers} handleNext={handleNext} question={getQuestion(step)} />
        );
    }

    const { question, explanation, explanation_incorrect } = getQuestion(step);
    return (
        <QuestionResult
            title={question}
            body={answers[answers.length - 1].correct
                ? explanation
                : explanation_incorrect}
            answers={answers}
        >
            <button className={`${style['stepper-button']} quiz-next`} onClick={() => setStep(step + 1)}>Gå vidare</button>
        </QuestionResult>
    );
}

const Questionnaire = ({ blok }: { blok: QuestionnaireStoryblok }) => {
    return (
        <div className={style.wrapper}>
            <video className={style.video} poster={`${blok.video[0].filename}/m/`} playsInline autoPlay muted loop>
                <source src={storyblokUrlToInternalUrl(blok.video[1]?.filename)} type="video/webm" />
                <source src={storyblokUrlToInternalUrl(blok.video[2]?.filename)} type="video/mov" />
                <source src={storyblokUrlToInternalUrl(blok.video[3]?.filename)} type="video/mp4" />
            </video>
            <QuestionnaireStepper blok={blok} />
        </div>
    );
}

export default Questionnaire;
